import { ThemeConfig } from 'antd'

const themeAntd: ThemeConfig = {
  token: {
    colorPrimary: '#16533d',
    colorInfo: '#16533d',
    colorSuccess: '#18be3f',
    colorWarning: '#fdcc4a',
    colorError: '#ff4f4f',
    colorTextBase: '#000000',
    borderRadius: 8
  },
  components: {
    Form: {
      labelColor: '#16533d',
      labelFontSize: 16
    }
  }
}

export default themeAntd
