import viVN from 'antd/locale/vi_VN'
import { ConfigProvider } from 'antd'
import { StrictMode, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { StyleProvider } from '@ant-design/cssinjs'

import themeAntd from '@/themes'
import RenderRouter from '@/routers'
import { Loading } from '@/components/custom'
import Spinner from '@/components/custom/spinner'
import '@/App.css'

function App() {
  return (
    <StrictMode>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <ConfigProvider locale={viVN} theme={themeAntd}>
            <StyleProvider>
              <Spinner>
                <RenderRouter />
              </Spinner>
            </StyleProvider>
          </ConfigProvider>
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}

export default App
