import { create } from 'zustand'

export type TStoreLoading = {
  loading: boolean
  setLoading: (loading: boolean) => void
}

export const useStoreLoading = create<TStoreLoading>((set) => ({
  loading: false,
  setLoading: (loading: boolean) => set({ loading })
}))
