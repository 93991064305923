export enum ROUTE_PATH {
  LOGIN = '/login',
  VERIFY_OTP = '/verify-otp',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  DASH_BOARD = '/dashboard',
  DUPLICATE = '/duplicate',
  OVERVIEW = '/overview',
  MANAGE_GOLF_COURSE = '/manage-golf-courses',
  MANAGE_ADMIN = '/manage-admins',
  MANAGE_CLB = '/manage-clubs',
  MANAGE_IMAGES = '/manage-images',
  MANAGE_REQUIREMENT = '/manage-requirement',
  MANAGE_CONFIG = '/golf-course-configures',
  MANAGE_BANNER = '/manage-banner',
  LEADERBOARD = '/',
  REGISTER_TOURNAMENT = '/register-tournament',
  NOT_FOUND = '*'
}
