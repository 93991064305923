import { Col, Row } from 'antd'
import { Outlet } from 'react-router-dom'
import { imgVisual } from '@/assets/images'

export default function PublicLayout() {
  return (
    <Row className='lg:max-h-screen h-screen relative'>
      <Col sm={24} xs={24} md={12} className='h-screen w-full'>
        <img
          src={imgVisual}
          className='object-cover h-screen w-full'
          alt='image visual'
          width={1000}
          height={1000}
          loading='lazy'
          decoding='async'
          referrerPolicy='same-origin'
        />
      </Col>
      <Col sm={24} xs={24} md={12} className='xs:absolute sm:absolute md:static top-0 h-full w-full'>
        <Outlet />
      </Col>
    </Row>
  )
}
