import request from '@/apis/request'

export default class BaseRequest {
  protected static abortController: AbortController = new AbortController()
  public static signal: AbortSignal = this.abortController.signal
  public static request = request
  public static status: boolean = false

  static setAbortController(abortController: AbortController) {
    if (BaseRequest.signal.aborted) {
      BaseRequest.abortController = abortController
      BaseRequest.signal = abortController.signal
    }
  }

  static setSignal(signal: AbortSignal) {
    BaseRequest.signal = signal
  }
  static abortRequest() {
    BaseRequest.abortController.abort()
  }
}
