import { PublicLayout } from '@/layouts'
import { RouteObject } from 'react-router-dom'
import { ROUTE_PATH } from '@/typescript/enums'
import { Login, ForgotPassword, VerifyOTP, ResetPassword } from '@/pages'

const routesPublic: RouteObject[] = [
  {
    element: <PublicLayout />,
    children: [
      {
        path: ROUTE_PATH.LOGIN,
        element: <Login />
      },
      {
        path: ROUTE_PATH.FORGOT_PASSWORD,
        element: <ForgotPassword />
      },
      {
        path: ROUTE_PATH.VERIFY_OTP,
        element: <VerifyOTP />
      },
      {
        path: ROUTE_PATH.RESET_PASSWORD,
        element: <ResetPassword />
      }
    ]
  }
]

export default routesPublic
