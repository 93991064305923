import type { IconNameProps } from '@/typescript/types'
import clsx from 'clsx'

type Props = {
  name: IconNameProps
  className?: string
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
}

export default function Icon(props: Props) {
  const { name, className, size } = props

  return <span className={clsx(`icon icon-${name} ${size}`, className)} />
}
