import React from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import routesPublic from '@/routers/public'
import { ROUTE_PATH } from '@/typescript/enums'

const routes: RouteObject[] = [
  ...routesPublic,
  {
    path: ROUTE_PATH.NOT_FOUND,
    element: <Navigate to={ROUTE_PATH.LOGIN} />
  }
]

const RenderRouter: React.FC = () => {
  const element = useRoutes(routes)
  return element
}

export default RenderRouter
