import React from 'react'

import Loading from '@/components/custom/loading'
import { useStoreLoading } from '@/stores/loading'

type TSpinnerProps = {
  children: React.ReactNode | React.ReactNode[] | string
}
export default function Spinner(props: TSpinnerProps) {
  
  const { loading } = useStoreLoading()
  return (
    <React.Fragment>
      {loading && <Loading />}
      {props.children}
    </React.Fragment>
  )
}
