import axios from 'axios'
import { message } from 'antd'

import BaseRequest from '@/apis/base'

const request = axios.create({
  baseURL: import.meta.env.LIVESCORE_API_URL,
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

request.interceptors.request.use((config) => {
  BaseRequest.setAbortController(new AbortController())
  config.signal = BaseRequest.signal
  return config
})

request.interceptors.response.use(
  (response) => {
    BaseRequest.status = false
    if (response?.data?.message) {
      message.success(response.data.message)
    }
    return response
  },
  (error) => {
    BaseRequest.status = false
    if (error?.response?.data?.message) {
      message.error(error.response.data.message)
    }
    return error?.response?.data
  }
)

export default request
